@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";

.App {
    height: 100%;
}

.settings {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 350px;
    z-index: 500;
}

.loading {
    position: absolute;
    top: 60px;
    left: 50%;
    z-index: 500;
}

.bp5-overlay {
    z-index: 510;
}

.bp5-overlay-backdrop {
    z-index: 510;
}

.overlay {
    z-index: 520;
    width: calc(min(600px, 90%));
    position: absolute;
    background-color: white;
    border-radius: 10px;
    border-color: gray;
    padding: 10px;
    top: 50px;
    left: calc(50% - min(300px, 45%));
    max-height: 80%;
    overflow: scroll;
}

img.gridImage {
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
}

.right {
    position: absolute;
    right: 0px;
}

.locationButton {
    position: absolute;
    right: 60px;
    top: 68px;
    z-index: 490;
}

.marginRight {
    margin-right: 10px;
}

div.restrictedSlider > .bp5-slider-track > .bp5-slider-progress:first-child {
    background-color: #888;
}

.heightPlotButton {
    position: absolute;
    bottom: 10px;
    z-index: 5000;
    left: calc(50% - 75px);
    width: 150px;
}

.heightPlotDrawer {
    z-index: 5001;
}

.heightPlotDrawerBody {
    display: flex;
    justify-content: center;
}

.invisibleCrosshair > .rv-crosshair__line {
    stroke-width: 0;
    width: 0px;
}